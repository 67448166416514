import { ref } from 'vue';
export default {
  setup() {
    const onClickLeft = () => history.back();
    const valuesearch = ref('');
    const active = ref('shop-home');
    return {
      onClickLeft,
      valuesearch,
      active
    };
  },
  data() {
    return {
      banners: {
        // 轮播图
        thumb: [require('../../assets/img/apple-1.jpg'), require('../../assets/img/apple-2.jpg'), require('../../assets/img/apple-3.jpg'), require('../../assets/img/apple-4.jpg')]
      }
    };
  }
};