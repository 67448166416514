import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "shop_home"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_van_search = _resolveComponent("van-search");
  const _component_van_swipe_item = _resolveComponent("van-swipe-item");
  const _component_van_swipe = _resolveComponent("van-swipe");
  const _component_van_notice_bar = _resolveComponent("van-notice-bar");
  const _component_van_grid_item = _resolveComponent("van-grid-item");
  const _component_van_grid = _resolveComponent("van-grid");
  const _component_van_tabbar_item = _resolveComponent("van-tabbar-item");
  const _component_van_tabbar = _resolveComponent("van-tabbar");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
    title: "商城首页",
    "left-text": "返回",
    "left-arrow": "",
    onClickLeft: $setup.onClickLeft
  }, null, 8, ["onClickLeft"]), _createVNode(_component_van_search, {
    modelValue: $setup.valuesearch,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.valuesearch = $event),
    placeholder: "请输入搜索关键词"
  }, null, 8, ["modelValue"]), _createVNode(_component_van_swipe, {
    autoplay: 3000,
    "lazy-render": "",
    class: "my_swipe"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.banners.thumb, thumb => {
      return _openBlock(), _createBlock(_component_van_swipe_item, {
        key: _ctx.imathumbge
      }, {
        default: _withCtx(() => [_createElementVNode("img", {
          src: thumb
        }, null, 8, _hoisted_2)]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  }), _createVNode(_component_van_notice_bar, {
    "left-icon": "volume-o",
    text: "无论我们能活多久，我们能够享受的只有无法分割的此刻，此外别无其他。"
  }), _createVNode(_component_van_grid, {
    border: false,
    clickable: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_van_grid_item, {
      icon: "play-circle-o",
      text: "观看直播",
      "icon-color": "var(--van-red)",
      badge: "2"
    }), _createVNode(_component_van_grid_item, {
      icon: "friends-o",
      text: "拼团活动",
      "icon-color": "var(--van-blue)"
    }), _createVNode(_component_van_grid_item, {
      icon: "cash-o",
      text: "砍价活动",
      "icon-color": "var(--van-orange)"
    }), _createVNode(_component_van_grid_item, {
      icon: "clock-o",
      text: "秒杀活动",
      "icon-color": "var(--van-orange-dark)"
    }), _createVNode(_component_van_grid_item, {
      icon: "coupon-o",
      text: "领卷优惠",
      "icon-color": "var(--van-red)"
    }), _createVNode(_component_van_grid_item, {
      icon: "comment-o",
      text: "实时咨询",
      "icon-color": "var(--van-green)"
    }), _createVNode(_component_van_grid_item, {
      icon: "like-o",
      text: "我的收藏",
      "icon-color": "var(--van-blue)",
      dot: ""
    }), _createVNode(_component_van_grid_item, {
      icon: "guide-o",
      text: "我的地址",
      "icon-color": "var(--van-orange)"
    })]),
    _: 1
  }), _createVNode(_component_van_tabbar, {
    modelValue: $setup.active,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.active = $event)
  }, {
    default: _withCtx(() => [_createVNode(_component_van_tabbar_item, {
      icon: "wap-home-o",
      name: "shop-home",
      replace: "",
      to: "shop-home"
    }, {
      default: _withCtx(() => [_createTextVNode("首页")]),
      _: 1
    }), _createVNode(_component_van_tabbar_item, {
      icon: "apps-o",
      name: "shop-class",
      replace: "",
      to: "shop-class"
    }, {
      default: _withCtx(() => [_createTextVNode("分类")]),
      _: 1
    }), _createVNode(_component_van_tabbar_item, {
      icon: "cart-o",
      name: "shop-buys",
      replace: "",
      to: "shop-buys"
    }, {
      default: _withCtx(() => [_createTextVNode("购物车")]),
      _: 1
    }), _createVNode(_component_van_tabbar_item, {
      icon: "user-o",
      name: "shop-ours",
      replace: "",
      to: "shop-ours"
    }, {
      default: _withCtx(() => [_createTextVNode("我的")]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"])]);
}